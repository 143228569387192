'use client';

import cn from 'classnames';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from '@headlessui/react';
import { MdKeyboardArrowRight } from 'react-icons/md';

type CollapseProps = {
  lang: string;
  item: any;
  variant?: 'gray' | 'transparent';
};

export const Accordion: React.FC<CollapseProps> = ({
  lang,
  item,
  variant = 'gray',
}) => {
  const { id, title, content } = item;
  return (
    <div className="w-full">
      <div className="w-full mx-auto mb-2.5 rounded shadow-category text-brand-light group">
        <Disclosure>
          {({ open }) => (
            <>
              <DisclosureButton
                className={cn(
                  ' flex justify-between w-full px-5 py-2 text-base font-medium 2xl:px-4 2xl:py-2 ltr:text-left rtl:text-right text-brand-dark focus:outline-none',
                  {
                    'bg-gray-50': variant === 'gray',
                  },
                )}
              >
                <span
                  className={cn(
                    'font-outfit text-sm font-normal leading-relaxed text-heading ltr:pr-2 rtl:pl-2',
                  )}
                >
                  {title}
                </span>
                <MdKeyboardArrowRight
                  className={`text-xl lg:text-2xl text-brand-dark text-opacity-60 group-hover:text-opacity-100 -mr-2 lg:-mr-1.5 shrink-0 ${
                    open ? 'transform rotate-90' : ''
                  }`}
                />
              </DisclosureButton>

              <Transition
                show={open}
                enter="transition duration-500 ease-out"
                enterFrom="transform scale-5 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-5 opacity-0"
              >
                <div>
                  <DisclosurePanel>
                    <p
                      dangerouslySetInnerHTML={{ __html: item.content }}
                      className="font-outfit px-5 pb-4 pt-3 text-sm 2xl:pb-7 2xl:px-4 2xl:pt-2.5 2xl:text-14px text-brand-dark opacity-70"
                    ></p>
                  </DisclosurePanel>
                </div>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default Accordion;
