import { TMetaTag } from "./config-types";

interface ContentConfig {
  heading: string;
  paragraph: string;
}

export const defaultCarouselBreakpoint = 
    {
        '1536': {
          slidesPerView: 4.5,
        },
        '1280': {
          slidesPerView: 4.5,
        },
        '1024': {
          slidesPerView: 4,
        },
        '768': {
          slidesPerView: 3,
        },
        '640': {
          slidesPerView: 4.5,
        },
        '0': {
          slidesPerView: 1.5,
        },
      }


export const landingHeaderConfig: Record<
  string,
  Record<string, ContentConfig>
> = {
  homepage: {
    sg: {
      heading: 'Customised Proposal Rings, Wedding Bands & Jewellery',
      paragraph:
        'Experience exquisite craftsmanship at Michael Trio, your premier online jewellery shop in Singapore. Buy jewellery online and explore our bespoke creations',
    },
    my: {
      heading: 'Bespoke Jewellery, One-of-a-kind Designs',
      paragraph:
        'Each piece is a unique work of art, crafted to reflect your personal style. Transform your vision into stunning jewellery with us.',
    },
    intl: {
      heading: 'Customised Proposal Rings, Wedding Bands & Jewellery',
      paragraph:
        'Experience exquisite craftsmanship at Michael Trio, your premier online jewellery shop in Singapore. Buy jewellery online and explore our bespoke creations',
    },
  },
  engagementRings: {
    sg: {
      heading: 'Bespoke Engagement Rings in Singapore',
      paragraph:
        'Find your forever sparkle with our proposal rings, from classic solitaires to modern masterpieces',
    },
    my: {
      heading: 'Engagement Rings',
      paragraph:
        'Find a ring that tells your story with Michael Trio, an engagement ring that binds your love forever.',
    },
    intl: {
      heading: 'Bespoke Engagement Rings in Singapore',
      paragraph:
        'Find your forever sparkle with our proposal rings, from classic solitaires to modern masterpieces',
    },
  },
  weddingBands: {
    sg: {
      heading: 'Wedding Bands in Singapore',
      paragraph:
        'From timeless eternity diamond rings to classic bands, each custom-made for your unique love story',
    },
    my: {
      heading: 'Wedding Rings',
      paragraph:
        "Let's 'I DO' with Michael Trio's stunning wedding rings, crafted for comfort and style.",
    },
    intl: {
      heading: 'Wedding Bands',
      paragraph:
        'From timeless eternity diamond rings to classic bands, each piece is custom-made to celebrate your unique love story',
    },
  },
  diamonds: {
    sg: {
      heading: 'Diamonds in Singapore',
      paragraph:
        'Explore our certified and ethically sourced diamonds to discover the perfect one for your ring.',
    },
    my: {
      heading: 'Diamonds',
      paragraph:
        'Explore our certified and ethically sourced diamonds to find the perfect one for your diamond ring.',
    },
    intl: {
      heading: 'Diamonds in Singapore',
      paragraph:
        'Explore our certified and ethically sourced diamonds to discover the perfect one for your ring.',
    },
  },
  gemstones: {
    sg: {
      heading: 'Gemstones Rings',
      paragraph:
        'Choose a unique engagement ring with vibrant Natural Gemstones like Sapphire, Ruby, and Emerald from our collection. Show your love in style.',
    },
    my: {
      heading: 'Gemstones',
      paragraph:
        'Embrace vibrant colors with our stunning gemstones, symbolizing your absolute love. Create a remarkable, personalized engagement ring or wedding ring with revered sapphires, rubies, emeralds, and more.',
    },
    intl: {
      heading: 'Gemstones Rings',
      paragraph:
        'Choose a unique engagement ring with vibrant Natural Gemstones like Sapphire, Ruby, and Emerald from our collection. Show your love in style.',
    },
  },
  jewellery: {
    sg: {
      heading: "Women's Jewellery Accessories",
      paragraph:
        'Covetable jewellery pieces hand-crafted for any occasion. Explore our extensive collection of everyday jewellery.',
    },
    my: {
      heading: 'Fine Jewellery Collection',
      paragraph:
        'Explore a curated selection of jewellery collection of rings, earrings, necklaces and bracelets, crafted with diamonds, gold, gemstones, and pearls.',
    },
    intl: {
      heading: "Women's Jewellery Accessories",
      paragraph:
        'Covetable jewellery pieces hand-crafted for any occasion. Explore our extensive collection of everyday jewellery.',
    },
  },
  gold: {
    sg: {
      heading: 'Gold Jewellery in Singapore',
      paragraph:
        'With the brilliance of 999 and 916 gold, our jewellery embodies elegance and sophistication. From necklaces, bracelets, rings to earrings, each piece is crafted to complement the timeless style of both men and women.',
    },
    my: {
      heading: 'Gold Jewellery',
      paragraph:
        'Radiating the brilliance of fine gold, each piece whispers a tale of refinement and charm. Perfect for standing out or blending in harmony, our selection of necklaces, bracelets, rings, and earrings is designed to adorn both men and women with timeless beauty.',
    },
    intl: {
      heading: 'Gold Jewellery in Singapore',
      paragraph:
        'With the brilliance of 999 and 916 gold, our jewellery embodies elegance and sophistication. From necklaces, bracelets, rings to earrings, each piece is crafted to complement the timeless style of both men and women.',
    },
  },
};

export const BannerContainerConfig: Record<
  string,
  Record<string, ContentConfig>
> = {
  pr1: {
    sg: {
      heading: 'Proposal Rings for Him & Her',
      paragraph:
        "Discover the elegance with Michael Trio's collection of engagement rings for him and her. Crafted with precision and passion, our offerings include diamond engagement rings, custom designs tailored to perfection, and lab diamond engagement rings. Our natural diamonds are certified by GIA and IGI.",
    },
    my: {
      heading: 'Our Handcrafted Rings',
      paragraph:
        'Experience artisanal elegance with our handcrafted ring collection. Each piece epitomizes timeless craftsmanship, adding sophistication to your style.',
    },
    intl: {
      heading: 'Proposal Rings for Him & Her',
      paragraph:
        "Discover the elegance with Michael Trio's collection of engagement rings for him and her. Crafted with precision and passion, our offerings include diamond engagement rings, custom designs tailored to perfection, and lab diamond engagement rings. Our natural diamonds are certified by GIA and IGI.",
    },
  },
  wb1: {
    sg: {
      heading: 'Find Your Right Size',
      paragraph:
        "Don't know your ring size and can't visit our showroom? No problem! Use our complimentary ring sizer at home for accurate measurements.",
    },
    my: {
      heading: 'Find Your Right Ring Size',
      paragraph:
        'Discover your ring size with our complimentary ring sizer for accurate measurements.',
    },
    intl: {
      heading: 'Find Your Right Size',
      paragraph:
        "Don't know your ring size and can't visit our showroom? No problem! Use our complimentary ring sizer at home for accurate measurements.",
    },
  },
};

export const CategoryFeedConfig: Record<
  string,
  Record<string, ContentConfig>
> = {
  homepage: {
    sg: {
      heading: 'Shop Our Jewellery Category',
      paragraph:
        'Timeless elegance awaits in our stunning jewellery collection. Shop online now for exquisite, must-have pieces!',
    },
    my: {
      heading: 'Fine Jewellery Edition',
      paragraph: '',
    },
    intl: {
      heading: 'Shop Our Jewellery Category',
      paragraph:
        'Timeless elegance awaits in our stunning jewellery collection. Shop online now for exquisite, must-have pieces!',
    },
  },
};

export const BridalCollectionConfig: Record<
  string,
  Record<string, ContentConfig>
> = {
  homepage: {
    sg: {
      heading: 'Bridal Jewellery Collection',
      paragraph:
        'Browse our luxury range of wedding jewellery, designed to enhance your beauty on your special day',
    },
    my: {
      heading: 'Bridal Jewellery Collection',
      paragraph:
        'Our wedding collection offers something for every style and preference, allowing you to discover the perfect jewellery pieces to treasure forever.',
    },
    intl: {
      heading: 'Bridal Jewellery Collection',
      paragraph:
        'Browse our luxury range of wedding jewellery, designed to enhance your beauty on your special day',
    },
  },
};

export const SettingCollectionConfig: Record<
  string,
  Record<string, ContentConfig>
> = {
  homepage: {
    sg: {
      heading: 'Shop Engagement Rings by Style',
      paragraph:
        'From classic solitaire to vintage-inspired designs, find your perfect match with our exquisite proposal rings collection.',
    },
    my: {
      heading: 'Shop Engagement Rings by Style',
      paragraph:
        'Explore our curated collection of engagement rings styles, each designed to reflect different tastes.',
    },
    intl: {
      heading: 'Shop Engagement Rings by Style',
      paragraph:
        'From classic solitaire to vintage-inspired designs, find your perfect match with our exquisite proposal rings collection.',
    },
  },
  engagementRings: {
    sg: {
      heading: 'Shop Engagement Rings by Style',
      paragraph:
        'From classic solitaire to vintage-inspired designs, find the perfect style of your ideal proposal ring.',
    },
    my: {
      heading: 'Shop Engagement Rings by Style',
      paragraph:
        'Explore our diverse collection of engagement ring styles to find the perfect symbol of your love.',
    },
    intl: {
      heading: 'Shop Engagement Rings by Style',
      paragraph:
        'From classic solitaire to vintage-inspired designs, find the perfect style of your ideal proposal ring.',
    },
  },
};

export const RingBuilderConfig: Record<string, ContentConfig> = {
  sg: {
    heading: 'Michael Trio Ring Builder',
    paragraph:
      'We will bring your dream engagement ring to life, whether you have a design in mind or need ideas.',
  },
  my: {
    heading: 'Michael Trio Ring Builder',
    paragraph:
      "Design your ideal jewellery with Michael Trio's customisation services, tailored to bring your ideas to life.",
  },
  intl: {
    heading: 'Michael Trio Ring Builder',
    paragraph:
      'We will bring your dream engagement ring to life, whether you have a design in mind or need ideas.',
  },
};

export const JewelleryCollectionConfig: Record<
  string,
  Record<string, Record<string, ContentConfig>>
> = {
  homepage: {
    sg: {
      default: {
        heading: 'Our Jewellery Collection',
        paragraph:
          'Explore our range of captivating jewelleries now and find the perfect accessory to elevate your style with elegance and grace.',
      },
      rings: {
        heading: 'Shop Rings',
        paragraph:
          'Elevate your style with our stunning rings for every occassions.',
      },
      necklaces: {
        heading: 'Shop Necklaces',
        paragraph:
          'Accessories with elegance - our necklaces elevate every looks.',
      },
      earrings: {
        heading: 'Shop Earrings',
        paragraph: 'Complete your looks with our dazzling earrings.',
      },
      bracelets: {
        heading: 'Shop Bracelets & Bangles',
        paragraph: 'Adorn your wrists with our chic bracelets.',
      },
    },
    my: {
      default: {
        heading: 'Our Jewellery Collection',
        paragraph:
          'Explore our range of jewellery showcases a stunning array of meticulously crafted pieces.',
      },
      rings: {
        heading: 'Shop Rings',
        paragraph:
          'Explore our diverse collection of rings for every occassions.',
      },
      necklaces: {
        heading: 'Shop Necklaces',
        paragraph: 'Find the perfect necklace to complement your style.',
      },
      earrings: {
        heading: 'Shop Earrings',
        paragraph: 'Complete your looks with our dazzling earrings.',
      },
      bracelets: {
        heading: 'Shop Bracelets & Bangles',
        paragraph: 'Adorn your wrists with our bracelets & bangles.',
      },
    },
    intl: {
      default: {
        heading: 'Our Jewellery Collection',
        paragraph:
          'Explore our range of captivating jewelleries now and find the perfect accessory to elevate your style with elegance and grace.',
      },
      rings: {
        heading: 'Shop Rings',
        paragraph:
          'Elevate your style with our stunning rings for every occassions.',
      },
      necklaces: {
        heading: 'Shop Necklaces',
        paragraph:
          'Accessories with elegance - our necklaces elevate every looks.',
      },
      earrings: {
        heading: 'Shop Earrings',
        paragraph: 'Complete your looks with our dazzling earrings.',
      },
      bracelets: {
        heading: 'Shop Bracelets & Bangles',
        paragraph: 'Adorn your wrists with our chic bracelets.',
      },
    },
  },
  diamonds: {
    sg: {
      default: {
        heading: 'Our Diamond Jewellery',
        paragraph:
          'Indulge in exquisite diamond jewellery in Singapore, where each piece sparkles with unmatched brilliance and sophistication.',
      },
      rings: {
        heading: 'Diamond Rings',
        paragraph: '',
      },
      necklaces: {
        heading: 'Diamond Necklaces',
        paragraph: '',
      },
      earrings: {
        heading: 'Diamond Earrings',
        paragraph: '',
      },
      bracelets: {
        heading: 'Diamond Bracelets',
        paragraph: '',
      },
    },
    my: {
      default: {
        heading: 'Our Diamond Jewellery',
        paragraph:
          'We offer classics and modern pieces, each crafted with precision to add beauty to your special moments.',
      },
      rings: {
        heading: 'Diamond Rings',
        paragraph: '',
      },
      necklaces: {
        heading: 'Diamond Necklaces',
        paragraph: '',
      },
      earrings: {
        heading: 'Diamond Earrings',
        paragraph: '',
      },
      bracelets: {
        heading: 'Diamond Bracelets',
        paragraph: '',
      },
    },
    intl: {
      default: {
        heading: 'Our Diamond Jewellery',
        paragraph:
          'Indulge in exquisite diamond jewellery in Singapore, where each piece sparkles with unmatched brilliance and sophistication.',
      },
      rings: {
        heading: 'Diamond Rings',
        paragraph: '',
      },
      necklaces: {
        heading: 'Diamond Necklaces',
        paragraph: '',
      },
      earrings: {
        heading: 'Diamond Earrings',
        paragraph: '',
      },
      bracelets: {
        heading: 'Diamond Bracelets',
        paragraph: '',
      },
    },
  },
};

export const TrendingJewelleryConfig: Record<
  string,
  Record<string, ContentConfig>
> = {
  homepage: {
    sg: {
      heading: 'Trending Jewellery',
      paragraph:
        'Elevate your look with our trending jewellery. Explore the latest styles and make a statement!',
    },
    my: {
      heading: 'Trending Jewellery',
      paragraph: '',
    },
    intl: {
      heading: 'Trending Jewellery',
      paragraph:
        'Elevate your look with our trending jewellery. Explore the latest styles and make a statement!',
    },
  },
};

export const TagUsConfig: Record<string, ContentConfig> = {
  sg: {
    heading: 'Tag Us @michaeltrio #michaeltrio',
    paragraph:
      'Experience the global journey of love through engagements shared by our lovely customers',
  },
  my: {
    heading: 'Tag Us @michaeltrio #michaeltrio',
    paragraph:
      'Experience the global journey of love shared by our lovely customers',
  },
  intl: {
    heading: 'Tag Us @michaeltrio #michaeltrio',
    paragraph:
      'Experience the global journey of love through engagements shared by our lovely customers',
  },
};

export const DesignRingConfig: Record<string, ContentConfig> = {
  sg: {
    heading: 'Design Your Diamond Engagement Ring',
    paragraph:
      'Design your dream diamond ring with custom options effortlessly.',
  },
  my: {
    heading: 'Design Your Diamond Engagement Ring',
    paragraph:
      'Looking for inspiration? Explore our gallery of custom-designed rings and start crafting your ideal engagement ring today.',
  },
  intl: {
    heading: 'Design Your Diamond Engagement Ring',
    paragraph:
      'Design your dream diamond ring with custom options effortlessly.',
  },
};

export const DiamondShapeConfig: Record<
  string,
  Record<string, ContentConfig>
> = {
  engagementRings: {
    sg: {
      heading: 'Shop Diamonds By Shape',
      paragraph:
        'Discover the perfect setting style for your dream engagement and bring your vision to life with our exquisite diamond rings selection.',
    },
    my: {
      heading: 'Shop Diamonds By Shape',
      paragraph:
        'Find the perfect diamond shape for your dream engagement ring with our exquisite selection.',
    },
    intl: {
      heading: 'Shop Diamonds By Shape',
      paragraph:
        'Discover the perfect setting style for your dream engagement and bring your vision to life with our exquisite diamond rings selection.',
    },
  },
  diamonds: {
    sg: {
      heading: 'Shop Diamonds By Shape',
      paragraph:
        'Discover the perfect setting style for your dream engagement ring or wedding band, and bring your vision to life with our selection of diamond rings.',
    },
    my: {
      heading: 'Shop Diamonds By Shape',
      paragraph:
        'Choose from a variety of shapes that cater to every taste and style:',
    },
    intl: {
      heading: 'Shop Diamonds By Shape',
      paragraph:
        'Discover the perfect setting style for your dream engagement ring or wedding band, and bring your vision to life with our selection of diamond rings.',
    },
  },
};

export const RequestRingSizerConfig: Record<string, ContentConfig> = {
  sg: {
    heading: 'Proposal Rings in Singapore',
    paragraph:
      "Over time, we have earned the distinction of being Singapore's foremost online destination for engagement rings. Explore our custom engagement ring service to create something truly unique. Request a ring-sizer, and we will send it promptly to help you design your perfect ring. For home measurements, we provide detailed guidance to ensure a comfortable and secure fit, reflecting our dedication to your satisfaction.",
  },
  my: {
    heading: 'Ring Sizing for Your Engagement Ring',
    paragraph:
      "At Michael Trio, we understand the importance of a perfect fit for your engagement ring. We provide complimentary ring-sizers to assist you in determining your ring size accurately. Simply request a ring-sizer from us, and we'll ensure it reaches you promptly.",
  },
  intl: {
    heading: 'Proposal Rings in Singapore',
    paragraph:
      "Over time, we have earned the distinction of being Singapore's foremost online destination for engagement rings. Explore our custom engagement ring service to create something truly unique. Request a ring-sizer, and we will send it promptly to help you design your perfect ring. For home measurements, we provide detailed guidance to ensure a comfortable and secure fit, reflecting our dedication to your satisfaction.",
  },
};

export const EngagementEducationFeedConfig: Record<string, ContentConfig> = {
  sg: {
    heading: 'Learn More About Engagement Rings',
    paragraph:
      'Discover valuable insights for selecting, purchasing, and maintaining your ideal symbol of commitment with our comprehensive ring knowledge resources.',
  },
  my: {
    heading: 'Engagement Rings Education',
    paragraph:
      'Discover valuable insights for selecting, purchasing, and maintaining your ideal symbol of commitment with our comprehensive ring knowledge resources.',
  },
  intl: {
    heading: 'Learn More About Engagement Rings',
    paragraph:
      'Discover valuable insights for selecting, purchasing, and maintaining your ideal symbol of commitment with our comprehensive ring knowledge resources.',
  },
};

export const WomenWeddingRingStyleConfig: Record<string, ContentConfig> = {
  sg: {
    heading: "Women's Wedding Rings Style",
    paragraph:
      'Elegant diamond wedding bands for women, crafted to celebrate love with timeless style and sparkle.',
  },
  my: {
    heading: "Shop Women's Wedding Ring by Styles",
    paragraph:
      "Discover the perfect wedding ring style, whether it's a timeless diamond solitaire or a one-of-a-kind modern masterpiece.",
  },
  intl: {
    heading: "Women's Wedding Rings Style",
    paragraph:
      'Elegant diamond wedding bands for women, crafted to celebrate love with timeless style and sparkle.',
  },
};

export const WBHerConfig: Record<string, ContentConfig> = {
  sg: {
    heading: 'Wedding Bands For Her',
    paragraph:
      "Captivating women's diamond wedding rings, designed to radiate love and elegance, symbolizing timeless commitment and cherished memories.",
  },
  my: {
    heading: "Top Selling Women's Wedding Rings Collection",
    paragraph:
      "Discover our wide range of women's wedding rings, in every available design and metal.",
  },
  intl: {
    heading: 'Wedding Bands For Her',
    paragraph:
      "Captivating women's diamond wedding rings, designed to radiate love and elegance, symbolizing timeless commitment and cherished memories.",
  },
};

export const AnniversaryRingConfig: Record<string, ContentConfig> = {
  sg: {
    heading: 'Anniversary Rings',
    paragraph:
      'Our anniversary rings, perfect with your engagement and wedding rings, but stunning on their own.',
  },
  my: {
    heading: 'Anniversary Rings',
    paragraph:
      'Mark special anniversaries with a beautiful anniversary ring from Michael Trio.',
  },
  intl: {
    heading: 'Anniversary Rings',
    paragraph:
      'Our anniversary rings, perfect with your engagement and wedding rings, but stunning on their own.',
  },
};

export const MenWeddingRingStyleConfig: Record<string, ContentConfig> = {
  sg: {
    heading: "Men's Wedding Rings Style",
    paragraph:
      "Adorn yourself with men's wedding rings showcasing unique designs and lasting quality for your special day.",
  },
  my: {
    heading: "Shop Men's Wedding Ring by Styles",
    paragraph:
      "Explore our range of men's wedding bands, designed with durability and style in mind.",
  },
  intl: {
    heading: "Men's Wedding Rings Style",
    paragraph:
      "Adorn yourself with men's wedding rings showcasing unique designs and lasting quality for your special day.",
  },
};

export const WBHimConfig: Record<string, ContentConfig> = {
  sg: {
    heading: 'Wedding Bands For Him',
    paragraph:
      "Stylish men's wedding bands, crafted with durable materials and sophisticated designs, perfect for his enduring commitment and unique style.",
  },
  my: {
    heading: "Men's Wedding Rings Collection",
    paragraph:
      "Explore our best selection of men's wedding rings, showcasing a variety of designs and metals to suit your style and preferences.",
  },
  intl: {
    heading: 'Wedding Bands For Him',
    paragraph:
      "Stylish men's wedding bands, crafted with durable materials and sophisticated designs, perfect for his enduring commitment and unique style.",
  },
};

export const WeddingEducationFeedConfig: Record<string, ContentConfig> = {
  sg: {
    heading: 'Learn More About Wedding Rings',
    paragraph:
      'Explore our blog for captivating insights and tips on choosing the perfect wedding ring, featuring expert advice and trends.',
  },
  my: {
    heading: 'Learn About Wedding Rings',
    paragraph: 'Expand your knowledge with our educational resources:',
  },
  intl: {
    heading: 'Learn More About Wedding Rings',
    paragraph:
      'Explore our blog for captivating insights and tips on choosing the perfect wedding ring, featuring expert advice and trends.',
  },
};

export const DesignOwnConfig: Record<string, ContentConfig> = {
  sg: {
    heading: 'Design Your Own Engagement Ring',
    paragraph:
      'Design your dream proposal ring with our custom options. Choose the perfect diamond, setting, and metal to capture your unique love story.',
  },
  my: {
    heading: 'Design Your Own Diamond Ring',
    paragraph:
      'Design your dream diamond ring for your engagement or wedding with our custom options.',
  },
  intl: {
    heading: 'Design Your Own Engagement Ring',
    paragraph:
      'Design your dream proposal ring with our custom options. Choose the perfect diamond, setting, and metal to capture your unique love story.',
  },
};

export const ColorDiamondShapeFeedConfig: Record<
  string,
  Record<string, ContentConfig>
> = {
  diamonds: {
    sg: {
      heading: 'Fancy Colour Diamonds',
      paragraph:
        'Experience the allure of fancy color diamonds in Singapore, where vibrant hues and exceptional quality redefine elegance.',
    },
    my: {
      heading: 'Colored Diamonds',
      paragraph:
        'Each stone is a testament to rarity and beauty, offering a unique touch to your jewellery collection.',
    },
    intl: {
      heading: 'Fancy Colour Diamonds',
      paragraph:
        'Experience the allure of fancy color diamonds in Singapore, where vibrant hues and exceptional quality redefine elegance.',
    },
  },
};

export const DiamondsEducationFeedConfig: Record<string, ContentConfig> = {
  sg: {
    heading: 'Learn More About Diamonds',
    paragraph:
      'Enhance your knowledge with our expert insights, guiding you through the intricacies of diamond quality, cut, and craftsmanship.',
  },
  my: {
    heading: 'Diamonds Education',
    paragraph:
      'Enhance your knowledge with our expert insights, guiding you through the intricacies of the diamond 4Cs.',
  },
  intl: {
    heading: 'Learn More About Diamonds',
    paragraph:
      'Enhance your knowledge with our expert insights, guiding you through the intricacies of diamond quality, cut, and craftsmanship.',
  },
};

export const GemstonesFeedConfig: Record<string, ContentConfig> = {
  sg: {
    heading: 'Shop by Gemstones',
    paragraph:
      'Explore our stunning collection of gemstone featuring stunning Topaz, Alexandrite, Aquamarine, Tsavorite, and more. Find your perfect gemstone piece today!',
  },
  my: {
    heading: 'Shop by Gemstones in Malaysia',
    paragraph:
      'Discover a world of enchanting gemstones collection, carefully curated to offer a wide range of gemstones that will captivate your senses as you shop at Michael Trio Malaysia.',
  },
  intl: {
    heading: 'Shop by Gemstones',
    paragraph:
      'Explore our stunning collection of gemstone featuring stunning Topaz, Alexandrite, Aquamarine, Tsavorite, and more. Find your perfect gemstone piece today!',
  },
};

export const GemstonesDesignConfig: Record<string, ContentConfig> = {
  sg: {
    heading: 'Design Your Own Gemstone Ring',
    paragraph:
      'Choose from diverse loose gemstones, pair with your desired setting and precious metal, crafting a unique and exceptional ring.',
  },
  my: {
    heading: 'Design Your Own Gemstone Ring',
    paragraph:
      'Choose from our extensive selection of loose gemstones for your engagement ring or wedding ring. Pair it with your preferred setting and select the precious metal of your choice, creating a truly unique and one-of-a-kind ring.',
  },
  intl: {
    heading: 'Design Your Own Gemstone Ring',
    paragraph:
      'Choose from diverse loose gemstones, pair with your desired setting and precious metal, crafting a unique and exceptional ring.',
  },
};

export const BridalCollectionImages = [
    {
        id: 1,
        name: 'Engagement Rings',
        href: 'engagement-rings',
        imageSrc: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/bridal-collection-signature.jpg',
        imageAlt: 'Solitaire, pavé, and channel-set diamond engagement ring',
    },
    {
        id: 2,
        name: 'Wedding Rings',
        href: 'wedding-bands',
        imageSrc: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/bridal-collection-wedding-band.jpg',
        imageAlt: 'White gold wedding ring with a refined brushed finish',
    },
    {
        id: 3,
        name: '999 Gold',
        href: 'golds',
        imageSrc: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/bridal-collection-999.jpg',
        imageAlt: '999 gold ingot necklace with a polished surface',
    },
    {
        id: 4,
        name: 'Si Dian Jin 四点金',
        href: 'golds/si-dian-jin',
        imageSrc: 'https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/assets/images/bridal-collection-sidianjin.jpg',
        imageAlt: 'Traditional Si Dian Jin gold jewelry set with red gemstone accents',
    },
];

export const DefaultMetaTags: TMetaTag[] = [
    {
        storeId: 0,
        page: 'home',
        title: 'Michael Trio - Forever Starts Now',
        description: "Michael Trio is Singapore's trusted jewellery shop. Explore our wide collection of proposal rings, wedding bands, diamonds, gemstones and more.",
        keywords: '',
        pageIdentifier: 'home',
    },
    {
        storeId: 2,
        page: 'home',
        title: 'Michael Trio - Forever Starts Now',
        description: "Michael Trio is Singapore's trusted jewellery shop. Explore our wide collection of proposal rings, wedding bands, diamonds, gemstones and more.",
        keywords: '',
        pageIdentifier: 'home-my',
    },

    {
        storeId: 3,
        page: 'home',
        title: 'Michael Trio - Forever Starts Now',
        description: "Michael Trio is Singapore's trusted jewellery shop. Explore our wide collection of proposal rings, wedding bands, diamonds, gemstones and more.",
        keywords: '',
        pageIdentifier: 'home-intl',
    },    

] as const;