'use client';
import { useState, useEffect } from 'react';
import Link from 'next/link';
import { setUrl } from '@utils/url';
import { landingHeaderConfig } from '@services/seo/config';

type EngagementRingsProps = {
  lang: string;
  customHeading?: string;
  customParagraph?: string;
  showButtons?: boolean;
};

export const EvergreenBanner: React.FC<EngagementRingsProps> = ({
  lang,
  customHeading,
  customParagraph,
  showButtons = true,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { heading, paragraph } =
    landingHeaderConfig.homepage[lang] || landingHeaderConfig.homepage.sg;

  useEffect(() => {
    const setVideoSource = () => {
      const videoElement = document.querySelector(
        '#banner-video',
      ) as HTMLVideoElement;

      if (!videoElement) return;

      const screenWidth = window.innerWidth;

      const source = Array.from(videoElement.querySelectorAll('source')).find(
        (source) => {
          const mediaQuery = source.media;
          return (
            (screenWidth <= 1024 && mediaQuery.includes('max-width')) ||
            (screenWidth > 1024 && mediaQuery.includes('min-width'))
          );
        },
      );

      if (source) {
        videoElement.src = source.src;
        videoElement.load();
        videoElement.oncanplaythrough = () => setIsLoading(false);
      }
    };

    // const debouncedSetVideoSource = debounce(setVideoSource, 200);

    setVideoSource();

    // window.addEventListener('resize', debouncedSetVideoSource);
    // return () => {
    //   window.removeEventListener('resize', debouncedSetVideoSource);
    //   debouncedSetVideoSource.cancel();
    // };
  }, []);

  return (
    <div className="relative">
      {isLoading && (
        <div className="relative bg-brand-blue-5 pb-[85%] lg:pb-[32.25%]">
          <div className="absolute flex items-center justify-center bg-opacity-50 z-10 h-full w-full">
            <div className="loader">
              <svg
                className="text-gray-300 animate-spin"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
              >
                <path
                  d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                  stroke="currentColor"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                  stroke="currentColor"
                  strokeWidth="5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="text-brand-blue-2"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      )}

      <div className={`${isLoading ? 'hidden' : ''}`}>
        <div className="relative w-full pb-[85%] lg:pb-[32.25%]">
          <video
            id="banner-video"
            className="absolute w-full h-auto top-0 left-0"
            autoPlay
            muted
            loop
            onLoadedData={() => setIsLoading(false)}
            onError={() => setIsLoading(false)}
            playsInline
            webkit-playsinline="true"
            disablePictureInPicture
            controlsList="nodownload"
          >
            <source
              src="https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/banners/sg/videos/welcome-banner-desktop.mp4"
              type="video/mp4"
              media="(min-width: 992px)"
            />
            <source
              src="https://michaeltrio.sgp1.cdn.digitaloceanspaces.com/banners/sg/videos/welcome-banner-mobile.mp4"
              type="video/mp4"
              media="(max-width: 992px)"
            />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="hidden lg:flex flex-col absolute bg-transparent min-w-[280px] text-[18px] font-normal font-outfit transform -translate-x-1/2 -translate-y-1/2 text-center box-border top-[48.7%] left-[78%] gap-2.5">
          <h1 className="text-left text-[32px] lg:text-4xl font-cardo font-normal text-brand-grey-0">
            {customHeading || heading}
          </h1>
          <div className="text-left py-2 lg:py-3 text-sm lg:text-base font-outfit font-light text-brand-grey-0">
            {customParagraph || paragraph}
          </div>
          {showButtons && (
            <div className="flex pt-2 gap-3">
              <div className="uppercase text-[#202020] leading-[1] whitespace-nowrap m-0 font-mulish text-center font-normal tracking-[.12vw] text-[1.47vw]">
                <Link
                  title="Shop Now"
                  href={setUrl('engagement-rings', lang)}
                  className="block h-[2.77vw] leading-[40px] font-semibold tracking-[.18vw] uppercase lg:flex items-center justify-center text-black bg-transparent w-[17.55vw] border border-black text-[.88vw] shadow-[3px_3px_7px_1px_rgba(23,23,23,.04)] hover:bg-black hover:text-white"
                >
                  Shop Engagement Rings
                </Link>
              </div>
              <div className="uppercase text-[#202020] leading-[1] whitespace-nowrap m-0 font-mulish text-center font-normal tracking-[.12vw] text-[1.47vw]">
                <Link
                  title="Shop Now"
                  href={setUrl('wedding-bands', lang)}
                  className="block h-[2.77vw] leading-[40px] font-semibold tracking-[.18vw] uppercase lg:flex items-center justify-center text-black bg-transparent w-[17.55vw] border border-black text-[.88vw] shadow-[3px_3px_7px_1px_rgba(23,23,23,.04)] hover:bg-black hover:text-white"
                >
                  Shop Wedding Rings
                </Link>
              </div>
            </div>
          )}
        </div>

        {/* Content for Mobile View */}
        <div className="lg:hidden px-6 py-4 text-center bg-white">
          <h1 className="text-xl font-semibold text-brand-grey-0 mb-2 font-cardo">
            {customHeading || heading}
          </h1>
          <p className="text-base text-brand-grey-0 mb-4 font-outfit">
            {customParagraph || paragraph}
          </p>
          {showButtons && (
            <div className="flex flex-col items-center gap-2 mt-3">
              <Link
                title="Shop Now"
                href={setUrl('engagement-rings', lang)}
                className="block w-full text-brand-grey-0 border border-brand-grey-0 py-2 text-base font-semibold hover:bg-black hover:text-white font-outfit"
              >
                Shop Engagement Rings
              </Link>
              <Link
                title="Shop Now"
                href={setUrl('wedding-bands', lang)}
                className="block w-full text-brand-grey-0 border border-brand-grey-0 py-2 text-base font-semibold hover:bg-black hover:text-white font-outfit"
              >
                Shop Wedding Rings
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
