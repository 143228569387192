'use client';
import Accordion from '../../../app/[lang]/(default)/faq/accordion';
import { usePathname } from 'next/navigation';

const faqs = [
  {
    page: 'engagement-rings',
    faq: [
      {
        title:
          "What's the difference between an engagement ring and wedding ring?",
        content:
          'An engagement ring is presented during a proposal or when a couple plans to marry, while a wedding ring is exchanged during the marriage ceremony, symbolizing the official union. These rings are commonly worn as tokens of love and commitment.',
      },
      {
        title: 'How much do you spend on an engagement ring?',
        content:
          "This depends on the person's preference on ring setting and diamonds. Usually, a couple would spend from $1000 or more.",
      },
      {
        title: 'Is a proposal ring different from an engagement ring?',
        content:
          'The terms engagement and proposal have the same meaning; they both describe the promise to enter into marriage. However, a marriage proposal is often referred to as the event of asking a partner to wed, whereas an engagement describes the time leading up to the wedding.',
      },
      {
        title: 'Do both partners wear engagement rings?',
        content:
          "Generally, women wear their engagement rings, while men's engagement ring practices vary based on cultural norms (such as in the Indian community) or personal choices.",
      },
      {
        title: "How do I find out my partner's ring size?",
        content:
          'We offer a free ring sizer so you can get the exact ring size of your partner. Alternatively, you can check our ultimate guidelines on finding the perfect ring size.',
      },
      {
        title: 'How do I choose an engagement ring?',
        content:
          'This varies from person to person, depending on their budget, lifestyle, preference. The process starts by selecting the ring setting, ring size, metal and diamond. For further customisation, you can design it with gemstones and include engravings as well.',
      },
    ],
  },
  {
    page: 'wedding-bands',
    faq: [
      {
        title: 'When is it a good time to buy my wedding bands?',
        content:
          'You should start looking for bands about 3-6 months before your wedding day. This leaves you with extra time should your rings take longer than expected to size or ship.',
      },
      {
        title: 'How much should you spend on a wedding band?',
        content:
          'The most comfortable price range you may expect can be be around from $1,000-$3,000 or more.',
      },
      {
        title: 'How do I choose my wedding bands?',
        content:
          "Do your research on what ring style you like. Find the right metal, ring size, design and your preferred budget. Also, you may consider whether you'd like to customise it with an engraving or a gemstone.",
      },
      {
        title: 'How do I find out my significant other’s ring size?',
        content:
          'We offer a free ring sizer so you can get the exact ring size of your partner. Otherwise, you can check our ultimate guidelines on finding the perfect ring size.',
      },
      {
        title: 'Which finger is the wedding band on?',
        content:
          'Wedding bands are almost always exclusively worn on the fourth finger of the left hand.',
      },
      {
        title:
          'Are all your wedding bands small diamonds natural or lab grown?',
        content:
          'Our collection boasts a wide array of natural and lab-grown diamond sizes and carats, primarily consisting of natural diamonds. The small diamonds in our wedding bands are of natural origin, featuring an average color grade of G, clarity in the VS range, and a very good cut.',
      },
    ],
  },
  {
    page: 'golds',
    faq: [
      {
        title: 'How often does gold prices change?',
        content:
          'Gold prices fluctuate very frequently due to changes in the global market. As such, our gold prices are adjusted accordingly to reflect these updates. If you’d like more information about current gold prices, feel free to visit our showroom or contact us via our online chat—our team will be happy to assist you.',
      },
      {
        title:
          'What is the difference between 916 Gold, 999 Gold and 18K Gold?',
        content:
          '<ul class="list-disc ml-3"><li><strong>999 Gold:</strong> Known as pure gold, 999 gold contains 99.9% gold content, making it highly valuable and soft, often used for investment pieces or traditional jewellery.</li><li><strong>916 Gold:</strong> Also known as 22K gold, it contains 91.6% gold content mixed with alloys, offering a balance between purity and strength, making it more suitable for daily wear jewellery.</li><li><strong>18K Gold:</strong> Composed of 75% gold mixed with other metals like silver or copper, 18K gold is more durable and suitable for modern jewellery designs while still retaining a luxurious gold appearance.</li></ul><p class="pt-2">If you are unsure which type suits your needs, visit us at a Michael Trio showroom to explore our collections or reach out via our online chat.</p>',
      },
      {
        title: 'Is 999 pure gold jewellery suitable for daily wear?',
        content:
          '<ul class="list-disc ml-3"><li><strong>999 Gold:</strong> Due to its softness and high purity (99.9% gold), 999 gold is more prone to scratches, bending, and wear. While stunning, it’s better suited for occasional wear or investment pieces.</li><li><strong>916 Gold:</strong> With 91.6% gold and added alloys for durability, 916 gold is stronger and more suitable for daily wear while still maintaining a high gold content.</li></ul>',
      },
      {
        title:
          'How should I maintain the shine and quality of 999 and 916 gold jewellery?',
        content:
          '<ul class="list-disc ml-3"><li>Avoid Chemicals: Keep away from perfumes, lotions, and cleaning products.</li><li>Clean Gently: Use warm soapy water and a soft cloth to clean.</li><li>Store Safely: Store in a soft pouch or separate compartment to prevent scratches.</li><li>Handle with Care: Avoid wearing during strenuous activities.</li><li>Professional Cleaning: Visit Michael Trio for expert cleaning and maintenance.</li></ul><p class="pt-2">Keep your gold jewellery shining with these simple tips! For more help, feel free to contact us or visit our showroom.</p>',
      },
      {
        title: 'What is 5G technology in Gold?',
        content:
          '<p>5G technology in gold refers to an advanced manufacturing process that enhances the quality, precision, and craftsmanship of gold jewellery. It ensures lighter, more intricate designs without compromising durability, giving you beautifully crafted and innovative pieces.</p><p>Explore Michael Trio’s 5G-crafted gold jewellery in-store or on our website for a closer look at these modern innovations. Our team is always ready to assist if you have any questions!</p>',
      },
      {
        title: 'Is 916 Gold Hypoallergenic Like 999 Gold?',
        content:
          'Both 999 and 916 gold are considered hypoallergenic to some extent because they have minimal alloy content. However:<ul class="list-disc ml-3 pt-2"><li><strong>999 Gold:</strong> Since it’s nearly pure gold (99.9%), it is generally safer for those with sensitive skin, though its softness makes it more prone to scratches.</li><li><strong>916 Gold:</strong> While it’s still high in gold content (91.6%), the added alloys (such as copper and silver) may cause a mild reaction in those extremely sensitive to metals.</li></ul><p class="pt-2">If you have known sensitivities, we recommend visiting Michael Trio to consult with our experts on the best options for you.</p>',
      },
      {
        title: 'Does Michael Trio offer a gold trade-in policy?',
        content: `<p>We are pleased to offer a trade-in policy for Michael Trio gold products crafted in 999 or 916 gold. Only Michael Trio gold products (999/916) are eligible, with trade-in value determined based on the gold weight (in grams) at the current market price, excluding taxes such as GST and workmanship costs, and an 18% deduction of the gold price.</p><p>Gold products in all conditions, including damaged items like crumpled gold foil, are accepted, subject to verification and evaluation. However, severe damages may impact eligibility or require further inspection.</p><p>All items must be weighed using Michael Trio's standardized equipment before the trade-in process is finalized. The trade-in value cannot be exchanged for cash and must be used toward purchases at Michael Trio. If the new purchase is of lesser gold weight than the trade-in value, no refunds or cash balances will be provided, and any balance exceeding the trade-in value must be topped up. Both the trade-in and the purchase must be completed on the same day, as trade-in value does not carry over.</p><p>Proof of purchase or authentication will be required to verify eligibility. Michael Trio reserves the right to amend or terminate this policy at any time without prior notice.</p>`,
      },
    ],
  },
];

export const Questionaire = () => {
  const pathname = usePathname();
  const [emptyPath, main, sub] = pathname.split('/');

  const currentFaq = faqs.find((faq) => faq.page === sub);

  return (
    <div className="flex flex-col max-w-2xl py-12 mx-auto 2xl:max-w-4xl md:py-20">
      <h2 className="font-cardo text-2xl md:text-4xl font-normal text-brand-grey-0 text-center mb-6">
        Frequently Asked Questions
      </h2>
      {currentFaq ? (
        currentFaq.faq.map((item, idx) => (
          <div key={`fq-${idx}`}>
            <Accordion item={item} lang={'en'} variant="transparent" />
          </div>
        ))
      ) : (
        <p>No FAQs available for this page.</p>
      )}
    </div>
  );
};
